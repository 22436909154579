<!-- FoldingComponent.vue -->
<template>
  <div
    :id="$attrs.id"
    class="flex rounded-2xl py-16 items-center"
    :class="{
      'bg-neutral-variant-2': projectType || termsType,
      'bg-[#F7FAFD]': !noBackground && !projectType && !termsType,
      'py-16': !noBackground,
      'py-4': noBackground
    }"
  >
    <button
      type="button"
      class="shrink-0 ml-20 h-24"
      :class="{
        'opacity-0 pointer-events-none': isDisabled,
        'ml-20': !noBackground,
        'ml-8': noBackground
      }"
      @click="onOpen"
    >
      <s-icon
        :size="projectType || termsType ? '4xl' : '3xl'"
        icon="ic-v2-control-arrow-right-line"
        class="text-on-surface-elevation-1 flex"
        :class="{ 'rotate-90': isOpenDetail }"
      />
    </button>
    <span
      v-if="termsType"
      class="shrink-0 w-116 px-12 text-md font-medium leading-lg break-all"
      :class="termsVersion === '-' ? 'text-placeholder' : 'text-on-surface-elevation-2'"
    >
      {{ termsVersion }}
    </span>
    <p
      v-else
      class="text-on-surface-elevation-2 break-all pr-12 flex-1"
      :class="{
        'text-md font-bold leading-lg pl-8': roleType && noBackground,
        'text-lg font-medium leading-lg pl-8': [roleType, roleAdminType] && !noBackground
      }"
    >
      {{ name }}
      <span v-if="projectSubDescription" class="block text-xs leading-xs text-placeholder">{{
        projectSubDescription
      }}</span>
    </p>
    <template v-if="projectType">
      <span
        v-if="projectCount && projectCount.length > 0"
        class="w-[16.2rem] pl-12 text-md leading-lg text-on-surface-elevation-2 font-medium"
        :class="{ 'flex-1': isFull }"
      >
        {{ projectCount }}
      </span>
      <span
        v-if="memberCount && memberCount.length > 0"
        class="w-[20rem] pl-12 text-md leading-lg text-on-surface-elevation-2 font-medium"
      >
        {{ memberCount }}
      </span>
      <span
        v-if="productCount && productCount.length > 0"
        class="w-116 pl-12 text-md leading-lg text-on-surface-elevation-2 font-medium"
      >
        {{ productCount }}
      </span>
      <span v-if="btnStorage" class="w-120 text-center">
        <s-button size="sm" variant="outline" @click="emits('onStorageBtnClick')">{{
          $t('studio.prj_prod_mngmt.btn_storage')
        }}</s-button>
      </span>
      <span
        v-if="creationDate && creationDate.length > 0"
        class="w-[15.4rem] pl-12 text-md leading-lg text-on-surface-elevation-2 font-regular"
      >
        {{ creationDate }}
      </span>
      <div v-if="useMoreActions" class="shrink-0 w-48 pl-12">
        <s-menu-popup
          :distance="0"
          :offset="[-12, 16]"
          placement="left-start"
          trigger="click"
          class="inline-block"
        >
          <template #target>
            <s-icon
              size="xl"
              icon="ic-v2-content-option-vertical-fill"
              class="text-on-surface-elevation-2"
            />
          </template>
          <template #menuItems>
            <s-menu-popup-item
              icon="ic-v2-community-pencil-line"
              class="min-w-[15.8rem]"
              value="edit"
              @click="emits('onEditClick')"
            >
              {{ $t('studio.prj_prod_mngmt.btn_prj_edit') }}
            </s-menu-popup-item>
            <s-menu-popup-item
              icon="ic-v2-object-delete-line"
              class="min-w-[15.8rem] text-tint-red-a400"
              value="delete"
              @click="emits('onDeleteClick')"
            >
              {{ $t('studio.prj_prod_mngmt.btn_prj_del') }}
            </s-menu-popup-item>
          </template>
        </s-menu-popup>
      </div>
    </template>
    <template v-if="termsType">
      <span
        v-if="termsTitle && termsTitle.length > 0"
        class="flex-1 pl-12 text-md font-medium leading-lg truncate"
        :class="termsVersion === '-' ? 'text-placeholder' : 'text-on-surface-elevation-2'"
      >
        <s-tooltip
          arrow
          :content="termsTitle"
          :duration="0"
          :distance="4"
          useFlip
          flipOnUpdate
          placement="bottom-start"
          trigger="mouseenter focus"
          :theme="'studio-tooltip'"
          :zIndex="2501"
          :allowHTML="true"
        >
          <template #target>
            {{ termsTitle }}
          </template>
        </s-tooltip>
      </span>
      <span
        v-if="termsSort && termsSort.length > 0"
        class="shrink-0 w-280 pl-12 text-md font-medium leading-lg text-on-surface-elevation-2"
      >
        {{ termsSort }}
      </span>
      <span
        v-if="termDate && termDate.length > 0"
        class="shrink-0 w-160 pl-12 text-md font-medium leading-lg text-on-surface-elevation-2"
      >
        {{ termDate }}
      </span>
    </template>
    <template v-if="roleType && !noCheckMark">
      <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
        <button
          type="button"
          class="inline-flex"
          disabled
          :class="{
            'text-on-surface-elevation-1': viewableCheck,
            'disabled:text-disabled-variant-1': !viewableCheck
          }"
        >
          <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
        </button>
      </span>
      <span class="w-[9.6rem] flex shrink-0 items-center justify-center pr-8">
        <button
          type="button"
          class="inline-flex"
          disabled
          :class="{
            'text-on-surface-elevation-1': viewableCheck,
            'disabled:text-disabled-variant-1': !viewableCheck
          }"
        >
          <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
        </button>
      </span>
    </template>
    <template v-if="roleAdminType">
      <template v-if="isEditable">
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
          <div class="h-16">
            <checkbox
              v-model="viewableCheck"
              :options="{
                size: 'sm',
                align: 'middle'
              }"
              :disabled="isViewableDisabled"
              @update:modelValue="emits('onViewableCheck', $event)"
            />
          </div>
        </span>
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
          <div class="h-16">
            <checkbox
              v-model="editableCheck"
              :options="{
                size: 'sm',
                align: 'middle'
              }"
              :disabled="isEditableDisabled"
              @update:modelValue="emits('onEditableCheck', $event)"
            />
          </div>
        </span>
      </template>
      <template v-else>
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center">
          <button
            type="button"
            class="inline-flex"
            disabled
            :class="{
              'text-on-surface-elevation-1': viewableCheck,
              'disabled:text-disabled-variant-1': !viewableCheck
            }"
          >
            <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
          </button>
        </span>
        <span class="w-[9.6rem] flex shrink-0 items-center justify-center pr-8">
          <button
            type="button"
            class="inline-flex"
            disabled
            :class="{
              'text-on-surface-elevation-1': editableCheck,
              'disabled:text-disabled-variant-1': !editableCheck
            }"
          >
            <s-icon icon="ic-v2-control-check-bold-line" size="3xl" />
          </button>
        </span>
      </template>
    </template>
  </div>
  <template v-if="isOpenDetail">
    <slot></slot>
  </template>
</template>

<script setup lang="ts">
import { defineModel, toRefs } from 'vue';

import Checkbox from '@/components/validation/checkbox.vue';

const props = defineProps<{
  name?: string; // projectName
  productCount?: string;
  projectCount?: string;
  projectType?: boolean;
  roleType?: boolean;
  memberCount?: string;
  creationDate?: string;
  roleAdminType?: boolean;
  termsVersion?: string;
  termsTitle?: string;
  termsSort?: string;
  termsType?: boolean;
  viewableCheck?: boolean;
  editableCheck?: boolean;
  isEditable?: boolean;
  isDisabled?: boolean;
  btnStorage?: boolean;
  isFull?: boolean;
  useMoreActions?: boolean;
  termDate?: string;
  isProjectNameFull?: boolean;
  noCheckMark?: boolean;
  noBackground?: boolean;
  projectSubDescription?: string;
}>();

const emits = defineEmits<{
  onFold: [v: boolean];
  onViewableCheck: [v: boolean];
  onEditableCheck: [v: boolean];
  onStorageBtnClick: [];
  onEditClick: [];
  onDeleteClick: [];
}>();

const { viewableCheck, editableCheck } = toRefs(props);

const isOpenDetail = defineModel<boolean>('isOpenDetail');
const isViewableDisabled = defineModel<boolean>('isViewableDisabled');
const isEditableDisabled = defineModel<boolean>('isEditableDisabled');

const onOpen = () => {
  isOpenDetail.value = !isOpenDetail.value;
  emits('onFold', !isOpenDetail.value);
};
</script>
